.search__wrapper {
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  margin-bottom: 10px;
  /* position: sticky; */
  /* padding: 0 10px; */
}

.Search_container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--inputColor);
  border-radius: 10px;
  padding: 10px;
  gap: 5px;
  height: auto;
}
.Search_content {
  /* background: #000; */
  width: 93%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.upper__content {
  width: 93%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.upper__content > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

.bottom__content {
  width: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom__content > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.bottom__locations :nth-child(1) {
  color: #f95f35;
  border-right: 1px solid #f95f35;
  padding: 0 5px;
}
.bottom__locations :nth-child(2) {
  color: #f95f35;
  border-right: 1px solid #f95f35;
  padding: 0 5px;
}
.bottom__locations :nth-child(3) {
  color: #f95f35;
  padding: 0 5px;
}

.Search_content > div :nth-child(1) {
  width: 18px;
}
.Search_content > div :nth-child(2) {
  font-size: 14px;
  font-weight: bold;
  color: gray;
}
.Search_container > textarea {
  background-color: transparent;
  border: none;
  outline: none;
}
/* .map__Wrapper {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
} */
.s__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* background: linear-gradient(106.23deg, #f99827, #f95f35 100%); */
  /* background: unset var(--buttonBg); */

  border-radius: 5px;
  padding: 4px;
  color: white;
  right: 5px;
  /* top: 25%; */
}

.s__icon:hover {
  cursor: pointer;
  background: linear-gradient(106.23deg, #f99827, #f95f35 100%);
  color: gray;
  transition: all 300ms ease-in;
}

.postOptions {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.option {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.option:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  /* .search__wrapper {
    position: fixed;
    width: 95%;
    top: 80px;
    z-index: 2;
  } */
}
