.map{
  position: relative;
  z-index: -1;
  height: 455px;
}
/* leaflet inner css start  */
.leaflet-container {
  position: unset;
    height: 100%;
    border-radius: 10px;
    
  }

  .leaflet-top {
    top: unset;
    bottom: 25px
  }
/* leaflet inner css end  */

 /* user location button start  */
  .current__location{
    position: absolute;
    bottom: 25px;
    right: 10px;
    z-index: 665;
  }
   /* user location button start  */


   /* map search input start  */
  .search-box{
    position: sticky;
    display: flex;
    width: 100%;
    z-index: 665;
    padding: 5px;
    
  }

  .search-box form{
    background: white;
    display: flex;
    border-radius: 10px;
    margin: auto;
  }
  .search-box span{
    color: red;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
/* map search input end  */
 
/* map search output start  */
  .search__results{
    position: sticky;
    width: 100%;
    z-index: 665;
    padding:  5px 0;
   
  }
  .search__list{
    list-style-type: none;
    padding: 0  25px;
    border-radius: 10px;
    background: #ffffff;
    border-radius: 10px;
   
  }
  .search__list li{
    border-bottom: 1px solid rgb(175, 163, 163);
  }
  .search__list li:hover{
    color: #000;
    font-weight: bolder;
  }
  /* map search output end  */


  @media screen and (max-width: 768px) {
    .map{
      z-index: 1;
    }
    
  }