.ProfileCard {
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.5rem;
  overflow-x: clip;
  background: white;
}

.ProfileImages {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProfileImages > img:nth-of-type(1) {
  width: 100%;
}
.ProfileImages > img:nth-of-type(2) {
  width: 6rem;
  border-radius: 50%;
  position: absolute;
  bottom: -3rem;
  box-lines: var(--profileShadow);
}

.ProfileName {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  gap: 10px;
}

.ProfileName > span:nth-of-type(1) {
  font-weight: bold;
}

/* Follow Status */

.followStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.followStatus > hr {
  width: 85%;
  border: 1px solid var(--hrColor);
}

.followStatus > div {
  display: flex;
  gap: 1rem;
  width: 80%;
  justify-content: space-around;
  align-items: center;
}

.follow {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
}

.follow > span:nth-of-type(1) {
  font-weight: bold;
}
.follow > span:nth-of-type(2) {
  color: var(--gray);
  font-size: 13px;
}

.vl {
  height: 50px;
  border-left: 2px solid var(--hrColor);
}

.ProfileCard > span {
  font-weight: bold;
  color: orange;
  align-self: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

/* Dark mode */
.dark .ProfileCard {
  background-color: rgb(25, 38, 45);
}
.dark .ProfileName,
.dark .follow > span:nth-of-type(2) {
  color: rgb(209, 236, 243);
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.dark .follow > span:nth-of-type(1) {
  color: rgb(243, 243, 243);
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
@media (prefers-color-scheme: dark) {
  .dark .ProfileCard {
    background-color: rgb(25, 38, 45);
  }
  .dark .ProfileName,
  .dark .follow > span:nth-of-type(2) {
    color: rgb(209, 236, 243);
    --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
      drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .dark .follow > span:nth-of-type(1) {
    color: rgb(243, 243, 243);
    --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
      drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
}
