.apartment__wrapper {
  position: relative;
}
.apartment__header {
  text-align: center;
  font-weight: bold;
  color: #676b6b;
  background: #fcfbfb;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 1px 4px 10px -2px rgba(66, 66, 66, 0.61);
  -webkit-box-shadow: 1px 4px 10px -2px rgba(66, 66, 66, 0.61);
  -moz-box-shadow: 1px 4px 10px -2px rgba(66, 66, 66, 0.61);
}
.double__content {
  display: flex;
  justify-content: center;
}
.double__content div {
  margin: 5px;
}
