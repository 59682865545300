.right__nav {
  height: 4rem;
  display: flex;
  /* width: 100%; */
  margin-bottom: 10px;
}

.navIcons {
  width: 100%;
  display: flex;
  /* grid-template-columns: auto auto auto auto; */
  justify-content: space-around;
  align-items: center;
}
.navIcons > a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navIcons > a:hover {
  /* background: unset;
  box-shadow: unset; */
  /* color: unset; */
  width: unset;
  cursor: pointer;
}

.message__container {
  /* height: calc(100vh - 100px); */
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .navIcons > a {
    /* color: gray; */
  }
  .message__container {
    position: fixed;
    bottom: 80px;
    height: calc(100vh - 80%);
  }
}
