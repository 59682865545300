.Posts {
        height: calc(100vh - 10px);
        overflow-y: scroll;
        border-radius: 10px;
}


@media screen and (max-width: 768px) {
  .Posts {
    height: calc(100vh - 253px);
    
    
}
}
