:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --red: #f92525;
  --btnHoverColor: #5bc8ab;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  /* --buttonBg: linear-gradient(98.63deg, #f9a225 0%, #f95f35 100%); */

  --buttonBg: linear-gradient(98.63deg, #5bc8ab 0%, #3cb7ca 100%);
  --removeButtonBg: linear-gradient(98.63deg, #e15a3c 0%, #d86c3a 100%);
  --warngingButton: linear-gradient(98.63deg, #b0f314 0%, #cdd620 100%);
  --warningHoverColor: #e5ee41;
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4cb256;
  --video: #4a4eb7;
  --location: #ef5757;
  --shedule: #e1ae4a;
}

.card {
  /* padding-bottom: 35px; */

  /* margin: 10px 0; */
  /* background: rgba(255, 255, 255, 0.5); */
  background: rgba(251, 252, 252, 0.9);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -moz-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
}
.title {
  color: gray;
  font-weight: 700;
}
.subtitle {
  color: gray;
  font-weight: 600;
}

input,
select {
  border: none;
  outline: none;
  background-color: var(--inputColor);
  border-radius: 8px;
  padding: 10px;
  flex: 1;
}
a {
  text-decoration: none;
  color: inherit;
}
.btns {
  height: 35px;
  padding: 7px;
  margin: 2px;
}

.button {
  background-image: linear-gradient(
    to right,
    #02aab0 0%,
    #00cdac 51%,
    #02aab0 100%
  );
  /* margin: 10px;
    padding: 15px 45px; */
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 10px;
  display: block;
  cursor: pointer;
  border: 3px solid #242d49;
}

.button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button:disabled {
  /* height: 2rem; */
  position: relative;

  width: auto;
  background: silver;
  pointer-events: none;
  cursor: not-allowed;
}

.removeButton {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  /* margin: 10px;
    padding: 15px 45px; */
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.removeButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.removeButton:disabled {
  background: silver;
  pointer-events: none;
  cursor: wait;
}

.warningButton {
  background-image: linear-gradient(
    to right,
    #ff8008 0%,
    #ffc837 51%,
    #ff8008 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.warningButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.warningButton:disabled {
  background: silver;
  pointer-events: none;
  cursor: wait;
}

.updateButton {
  background-image: linear-gradient(
    to right,
    #f09819 0%,
    #edde5d 51%,
    #f09819 100%
  );
  /* margin: 10px;
    padding: 15px 45px; */
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
}

.updateButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.error {
  /* padding: 10px 20px; */
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bolder;
  font-size: 12px;
  color: #fc531f;
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/*********************************************************** 3 dot loading spinner start *********************************************************/
.spinner {
  margin: auto;
  width: 74px;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  /* width: fit-content; */
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  /* background-color: rgb(255, 255, 255); */
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 18%,
    rgba(96, 96, 96, 1) 80%
  );

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*********************************************************** 3 dot loading spinner end *********************************************************/

/********************************************************** popup Modal design start  ***********************************************************/
.modal__Body {
  background: transparent;
}
.modal__title {
  color: aliceblue;
  font-size: 12px;
  font-weight: bold;
  padding: 0 20px;
}
.modal__close {
  background: rgb(255, 255, 255);
  border-radius: 50%;
}
.modal__close:hover {
  color: rgb(153, 0, 0);
}

/************************************************************ popup Modal design end  ************************************************************/

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  border: 0;
  background-color: #a8a8a8;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgba(206, 206, 206, 0.7)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
  mask-image: linear-gradient(to bottom, transparent, black);
  -webkit-mask-image: linear-gradient(to bottom, #0000, #000);
}

/* Dark mode */

.dark .card {
  /* background: rgba(124, 124, 124, 0.9); */
  /* background: rgb(148 163 184 / 0.7); */
  background-color: rgb(25, 38, 45);
  box-shadow: 0 8px 32px 0 rgba(14, 28, 26, 0.37);
  -webkit-box-shadow: 0 8px 32px 0 rgba(14, 28, 26, 0.37);
  -moz-box-shadow: 0 8px 32px 0 rgba(14, 28, 26, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(153, 153, 153, 0.18);
  border-radius: 10px;
}
.dark .title {
  color: rgb(238, 238, 238);
  font-weight: 700;
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.dark .subtitle {
  color: rgb(202, 201, 201);
  font-weight: 600;
}

.dark .mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  border: 0;
  background-color: #131623;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(148 163 184 / 0.05) '%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");

  mask-image: none;
  -webkit-mask-image: none;
}
@media (prefers-color-scheme: dark) {
  .dark .card {
    /* background: rgba(124, 124, 124, 0.9); */
    /* background: rgb(148 163 184 / 0.7); */
    background-color: rgb(25, 38, 45);
    box-shadow: 0 8px 32px 0 rgba(14, 28, 26, 0.37);
    -webkit-box-shadow: 0 8px 32px 0 rgba(14, 28, 26, 0.37);
    -moz-box-shadow: 0 8px 32px 0 rgba(14, 28, 26, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(153, 153, 153, 0.18);
    border-radius: 10px;
  }
  .dark .title {
    color: rgb(238, 238, 238);
    font-weight: 700;
    --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
      drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .dark .subtitle {
    color: rgb(202, 201, 201);
    font-weight: 600;
  }

  .dark .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    border: 0;
    background-color: #131623;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(148 163 184 / 0.05) '%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");

    mask-image: none;
    -webkit-mask-image: none;
  }
}
