.homeWrapper {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  height: 100vh;
  width: 100vw;
  /* margin-left: -10px; */
  overflow: hidden;
}
/* left Side */
.leftSide__wrapper {
  /* background-color: rgb(195, 136, 136); */
  padding: 10px;
  z-index: 5;
}
.leftSide__header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  margin-bottom: 10px;
}
.leftSide__header > img {
  /* margin-right: 20px; */
  height: 40px;
}
.leftSide__header > span {
  color: var(--orange);
  height: 1.3rem;
}

.leftSide__container {
  display: block;
  position: relative;
  width: 100%;
}

/* post Side */
.postSide__wrapper {
  padding: 10px;
  /* flex-direction: column; */
  /* gap: 1rem; */
  height: 100vh;
  overflow: hidden;
  border-radius: 10px;
  /* width: 80%; */
}

/* right Side */
.rightSide__wrapper {
  /* background-color: rgb(136, 195, 176); */
  display: inline-block;
  padding: 10px;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .homeWrapper {
    grid-template-columns: 1fr;
  }

  .leftSide__wrapper {
    width: 100%;
    position: fixed;
    top: 0;
  }
  .postSide__wrapper {
    width: 100vw;
    margin-top: 165px;
    height: calc(100vh - 235px);
  }
  .rightSide__wrapper {
    width: 100%;
    position: fixed;
    bottom: -10px;
    /* margin-left: -10px; */
  }
}
