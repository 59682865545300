.floating-dropdown-button {
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  }
  
  .floating-dropdown-button .floating-button {
    position: absolute;
    bottom: 100px;
    left: 20px;
    border: none;
    border-radius: 50%;
    background-color: #2196f3;
    color: white;
    font-size: 24px;
    width: 56px;
    height: 56px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .floating-dropdown-button .options {
    position: absolute;
    bottom: 160px;
    left: 20px;
    /* background-color: white; */
    /* border: 1px solid #ccc; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    
    z-index: 100;
  }
  
  .floating-dropdown-button .option {
    /* padding: 10px; */
    width: 56px;
    height: 56px;
    margin-bottom: 3px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(
      to right,
      #02aab0 0%,
      #00cdac 51%,
      #02aab0 100%
    );
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    cursor: pointer;
  }
  
  .floating-dropdown-button .option:hover {
    background-color: #f2f2f2;
  }
  